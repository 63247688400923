

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';



const Bookbutton = styled.button`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
height: 100%;
padding: 0.8em 2.4em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
font-family: azo-sans-web, sans-serif;
color: #fff;
font-size: 0.7em;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
cursor: pointer;
border:none;
background-color: #00afc9;
color: #fff;
opacity: 0.8;
transition: 0.3s;

&:hover{
  opacity: 1
}
`;





const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: #999;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #999;
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 3.2em;

      margin-bottom: 0.4em;
 
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 26px;
   
      }

`;


const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function PRPLossresfaq({setIsModalOpen}) {
    return (
        <Section py="4" id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
          <Container className="pb-md-5 mb pt-md-5">
            <Row className="justify-content-center">
              <Col lg="12">

                <Iwrap>


                  <Itext as="h2">Questions about PRP For Hair Restoration</Itext>
                </Iwrap>
                <SecondText>
                  Frequently Asked Questions

                </SecondText>

              </Col>
            </Row>


            <Row className="">

              <Col

              >
                <Accordion allowZeroExpanded className="shadow">
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Is PRP Hair restoration treatment painful?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      The process is painless and non-invasive. The needles used to inject the scalp are among the
                      thinnest, making the procedure virtually painless. A local anaesthetic cream is also offered if
                      needed.
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion> <br />


                <Accordion allowZeroExpanded className="shadow">
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        How many PRP Hair Restoration sessions are required?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        For a full course treatment, 4-6 sessions are recommended, dependent on the individual.


                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>

                <br />


                <Accordion allowZeroExpanded className="shadow">

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What happens during the appointment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      We start with a thorough consultation to establish your needs and suitability. Once we have a
                      comprehensive knowledge of your situation and you agree to proceed, we will begin treatment.
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>


                <br />


                <Accordion allowZeroExpanded className="shadow">

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        PRP hair restoration treatment recovery time?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      No downtime required; you can continue your daily activities right after treatment. Allow the
                      scalp to heal for 24 hours after each session by not washing your hair or exerting any pressure
                      (no head massage, hair dressing) immediately to the scalp or hair. Please keep the area clean of
                      dirt and sweat to avoid infection. Additional post-treatment recommendations will be discussed
                      during the visit.
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>

                <br />

                <Accordion allowZeroExpanded className="shadow">
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Side effects of PRP hair restoration treatment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>

                      As the is provided using only natural and safe injections of one's own blood, there is no harmful
                      substance in it. As a result, having an allergic reaction or experiencing unfavourable side
                      effects from the chemical is extremely rare.
                      <br /> <br />
                      There may be some redness, swelling, and minor discomfort at the injection sites, as with any
                      injectable treatment. This isn't something to be concerned about, and it will go away after a few
                      days of treatment.


                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
                <br />
                <Accordion allowZeroExpanded className="shadow">

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Is PRP safe? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      Yes, due to it being a completely natural treatment that uses your own blood, it is safe.
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
                <br />


                <Accordion allowZeroExpanded className="shadow">

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Why choose Dermamina?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We're known for providing natural-looking but life-changing results to our patients while also
                        being completely honest with them. We will not recommend that you continue with a if we believe
                        it is not right for you.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion>

                <br />
                <Accordion allowZeroExpanded className="shadow">
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What if I change my mind after the consultation?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        If you change your mind after the consultation, you will be charged a £20 consultation fee and
                        the remaining amount will be refunded

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>


              </Col>


            </Row>


            <br /> <br />


            <div onClick={() => setIsModalOpen(true)} activeClassName="active">
              <Bookbutton>Book Appointment Online</Bookbutton>
            </div>


          </Container>
        </Section>


    );
}

